<template>

    <section class="section">
        <div class="container has-text-left">

            <div class="content">
                <h1 class="title is-4">Impressum</h1>
                <p>
                    Familienpflege Belzer GmbH<br/>
                    Hölderlinstraße 25<br/>
                    75038 Oberderdingen<br/>
                    Geschäftsführer Waltraud Belzer, Markus Belzer<br/><br/>

                    Handelsregister Mannheim: HRB 733679<br/><br/>

                    Telefon: <a href="tel:07045200277">07045 - 200277</a><br/>
                    Fax: 07045 - 40193<br/>
                    E-Mail: belzer@familienpflege-belzer.de<br/>


                </p>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
