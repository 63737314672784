<template>

    <section class="section">
        <div class="container has-text-left">

            <div class="content">
              <script v-html=jobJsonLd() type="application/ld+json"/>

              <h1 class="title is-4">Stellenangebote</h1>

                <p>
                    <b>Wir sind</b><br>
                    ein führender Familienpflegeanbieter und Haushaltshilfeanbieter in den Regionen Karlsruhe, Pforzheim, Ludwigsburg, Heilbronn, Heidelberg und darüber hinaus.<br>
                    <br>
                    <b>Wir bieten</b><br>
                    - attraktive Bezahlung<br>
                    - Kilometergeld für den Weg zur Arbeit<br>
                    - Mitarbeiterbetreuung und Mediation<br>
                    <br>
                    <b>Aufgaben</b><br>
                    - Kinderbetreuung aller Altersstufen, oft kleine Kinder<br>
                    - Kochen für die Familie<br>
                    - Haushaltspflege / Wäschepflege<br>
                    <br>
                    <b>Sie haben</b><br>
                    - ein Herz für Kinder und deren kranke Mütter<br>
                    - Kenntnisse in der Kinderpflege und Betreuung<br>
                    - Kenntnisse in der Haushaltspflege<br>
                    - Kochkenntnisse<br>
                    - Sie wollen helfen<br>
                    <br>
                    <b>Sie sind</b><br>
                    - flexibel in der Arbeitszeit<br>
                    - kontaktfreudig<br>
                    - zuverlässig<br>
                    <br>
                    <b>Wir suchen</b> Mitarbeiter/innen mit dem Abschluss der<br>
                    - Familienpflegerin<br>
                    - Hauswirtschafterin<br>
                    - Heilerzieherin<br>
                    - Frauen und Männer mit Erfahrungen in diesem Bereich<br>

                    <br><br>
                    Für eine Bewerbung bitten wir um Ihren Anruf unter 07045 200277

                </p>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        },
      methods:{
        jobJsonLd() {
          var jsonLdBreadcrumb= {
            "@context" : "https://schema.org/",
            "@type" : "JobPosting",
            "title" : "Familienpfleger/in",
            "description" : " <p>\n" +
                "                    <b>Wir sind</b><br>\n" +
                "                    ein führender Familienpflegeanbieter und Haushaltshilfeanbieter in den Regionen Karlsruhe, Pforzheim, Ludwigsburg, Heilbronn, Heidelberg und darüber hinaus.<br>\n" +
                "                    <br>\n" +
                "                    <b>Wir bieten</b><br>\n" +
                "                    - attraktive Bezahlung<br>\n" +
                "                    - Kilometergeld für den Weg zur Arbeit<br>\n" +
                "                    - Mitarbeiterbetreuung und Mediation<br>\n" +
                "                    <br>\n" +
                "                    <b>Aufgaben</b><br>\n" +
                "                    - Kinderbetreuung aller Altersstufen, oft kleine Kinder<br>\n" +
                "                    - Kochen für die Familie<br>\n" +
                "                    - Haushaltspflege / Wäschepflege<br>\n" +
                "                    <br>\n" +
                "                    <b>Sie haben</b><br>\n" +
                "                    - ein Herz für Kinder und deren kranke Mütter<br>\n" +
                "                    - Kenntnisse in der Kinderpflege und Betreuung<br>\n" +
                "                    - Kenntnisse in der Haushaltspflege<br>\n" +
                "                    - Kochkenntnisse<br>\n" +
                "                    - Sie wollen helfen<br>\n" +
                "                    <br>\n" +
                "                    <b>Sie sind</b><br>\n" +
                "                    - flexibel in der Arbeitszeit<br>\n" +
                "                    - kontaktfreudig<br>\n" +
                "                    - zuverlässig<br>\n" +
                "                    <br>\n" +
                "                    <b>Wir suchen</b> Mitarbeiter/innen mit dem Abschluss der<br>\n" +
                "                    - Familienpflegerin<br>\n" +
                "                    - Hauswirtschafterin<br>\n" +
                "                    - Heilerzieherin<br>\n" +
                "                    - Frauen und Männer mit Erfahrungen in diesem Bereich<br>\n" +
                "\n" +
                "                    <br><br>\n" +
                "                    Für eine Bewerbung bitten wir um Ihren Anruf unter 07045 200277\n" +
                "\n" +
                "                </p>",
            "employmentType" : "FULL_TIME",
            "hiringOrganization" : {
              "@type" : "Organization",
              "name" : "Familienpflege Belzer",
              "sameAs" : "https://www.familienpflege-belzer.de",
            },
            "datePosted" : "2020-12-09",
            "jobLocation": {
              "@type": "Place",
              "name": "Familienpflege Belzer",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Hölderlinstraße 25",
                "addressLocality": "Oberderdingen",
                "postalCode": "75038",
                "addressCountry": "Deutschland"
              }
            },

          };

          var days=7;
          var date = new Date();
          var last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
          var day =last.getDate();
          var month=last.getMonth()+1;
          var year=last.getFullYear();

          jsonLdBreadcrumb['datePosted']=year+'-'+month+'-'+day;

          return jsonLdBreadcrumb;
        }
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
