<template>

    <section class="section">
        <div class="container has-text-left">

            <h1 class="title is-4">Gesetzliche Lage</h1>

            <p>
                Es gibt einen rechtlichen Anspruch auf Haushaltshilfe. Sie können sich hier über das Gesetz im Sozialgesetzbuch informieren.
            </p>

            <h1 class="title is-5">§ 38 SGB V (Sozialgesetzbuch)</h1>

            <p>
            (1) Versicherte erhalten Haushaltshilfe, wenn ihnen wegen Krankenhausbehandlung oder wegen einer Leistung nach § 23 Abs. 2 oder 4, §§ 24, 37, 40 oder § 41 die Weiterführung des Haushalts nicht möglich ist. Voraussetzung ist ferner, daß im Haushalt ein Kind lebt, das bei Beginn der Haushaltshilfe das zwölfte Lebensjahr noch nicht vollendet hat oder das behindert und auf Hilfe angewiesen ist.
            </p>

            <p>
            (2) Die Satzung kann bestimmen, daß die Krankenkasse in anderen als den in Absatz 1 genannten Fällen Haushaltshilfe erbringt, wenn Versicherten wegen Krankheit die Weiterführung des Haushalts nicht möglich ist. Sie kann dabei von Absatz 1 Satz 2 abweichen sowie Umfang und Dauer der Leistung bestimmen.
            </p>
            (3) Der Anspruch auf Haushaltshilfe besteht nur, soweit eine im Haushalt lebende Person den Haushalt nicht weiterführen kann.

            <p>
            (4) Kann die Krankenkasse keine Haushaltshilfe stellen oder besteht Grund, davon abzusehen, sind den Versicherten die Kosten für eine selbstbeschaffte Haushaltshilfe in angemessener Höhe zu erstatten. Für Verwandte und Verschwägerte bis zum zweiten Grad werden keine Kosten erstattet; die Krankenkasse kann jedoch die erforderlichen Fahrkosten und den Verdienstausfall erstatten, wenn die Erstattung in einem angemessenen Verhältnis zu den sonst für eine Ersatzkraft entstehenden Kosten steht.
            </p>

            <p>
            (5) Versicherte, die das 18. Lebensjahr vollendet haben, leisten als Zuzahlung je Kalendertag der Leistungsinanspruchnahme den sich nach § 61 Satz 1 ergebenden Betrag an die Krankenkasse.
            </p>

            <p>
                Quelle: <a href="https://www.sozialgesetzbuch-sgb.de/sgbv/38.html">www.sozialgesetzbuch-bundessozialhilfegesetz.de</a>
            </p>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
   p {
       padding-bottom: 20px;
   }
</style>
