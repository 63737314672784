<template>

    <section class="section">
        <div class="container has-text-left">

            <div class="content">
                <h1 class="title is-4">Datenschutzerklärung</h1>
                <p>

                    Herzlich Willkommen auf unserer Internet –Seite der Familienpflege Belzer.
                    Auf dieser Seite werden in der Regel von uns keine personenbezogenen Daten erfasst und erhoben. Nur vom Websitebetreiber werden Daten erfasst, soweit Ihr Browser dies zulässt. Folgendes wird erfasst und gespeichert: Browsertyp, Name Ihres Internet Service Provider, die Seiten die Sie bei uns besuchen, die Website von der aus Sie uns besuchen, Datum und Dauer Ihres Besuches.
                    Personenbezogene Daten sind Daten, die dafür genutzt werden können um Ihre Identität festzustellen.
                    Diese Daten können für statistische Zwecke genutzt werden um unsere Internetseite und unseren Service zu verbessern. Die Daten können keiner bestimmten Personen zugeordnet werden.  Eine Zusammenführung von Informationen mit anderen Datenquellen erfolgt nicht.

                    <br/><br/>
                    <b>Matomo Statistik</b><br>
                    Dieses Angebot benutzt Matomo, eine Open-Source-Software zur statistischen Auswertung der Nutzerzugriffe. Die IP-Adresse wird sofort nach der Verarbeitung und vor deren Speicherung anonymisiert. Die Nutzung von Cookies wurde in Matomo deaktiviert.
                </p>
                    <iframe
                            style="border: 0; height: 200px; width: 600px;"
                            src="https://statistik.familienpflege-belzer.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=ffffff&fontColor=&fontSize=1em&fontFamily=BlinkMacSystemFont%2C%20-apple-system%2C%20%22Segoe%20UI%22%2C%20%22Roboto%22%2C%20%22Oxygen%22%2C%20%22Ubuntu%22%2C%20%22Cantarell%22%2C%20%22Fira%20Sans%22%2C%20%22Droid%20Sans%22%2C%20%22Helvetica%20Neue%22%2C%20%22Helvetica%22%2C%20%22Arial%22%2C%20sans-serif"
                    ></iframe>
                <p>
                    <b>Links</b><br/>
                    Diese Internetseite kann Links  enthalten mit denen Sie auf Internetseiten von externen Betreibern kommen.  Es ist nicht ausgeschlossen, dass im Nachhinein inhaltliche Veränderungen durch die jeweiligen Betreiber vorgenommen wurden und werden. Für den Inhalt wird keine Haftung übernommen. Die Datenschutzerklärung für andere Anbieter wird nicht übernommen, wenden Sie sich diesbezüglich an den jeweiligen Anbieter. Diese Datenschutzerklärung gilt nur für unseren eigenen Internetauftritt.

                    <br/><br/>
                    <b>Datenerhebung und Verarbeitung</b><br/>
                    Personenbezogene Daten werden von uns nur erfasst, wenn Sie uns diese mitteilen. Sie werden in diesem Fall ausschließlich zur Bearbeitung Ihrer Anfrage  bzw. bei der späteren Erteilung eines Auftrages verwendet. Hierfür werden Ihre Daten nur dann an Dritte weitergegeben soweit dies zur Ausführung der Auftragsabwicklung, Abrechnung, Auftragsdatenverarbeitung nötig ist. Das gültige Datenschutzgesetz wird dabei beachtet. Desweiteren sind unsere  Mitarbeiter zur Verschwiegenheit und zur Einhaltung des Datengeheimnisses verpflichtet worden. Die Daten werden, soweit durch Gesetze vorgeschrieben, aufbewahrt.
                    Daten werden von uns nicht an Dritte verkauft. Eine Weitergabe an staatliche Behörden und Einrichtungen geschieht nur  im Rahmen zwingender Rechtsvorschriften.
                    Bei elektronischer Kommunikation z.B. per E-Mail, Datenübertragung im Internet können Sicherheitslücken bestehen. Wir können daher keinen lückenlosen Schutz garantieren.

                    <br/><br/>
                    <b>Ihre Rechte</b><br/>
                    Auf Aufforderung teilen wir Ihnen, entsprechend den gelten Gesetzen und Bestimmungen, mit, ob und welche personenbezogenen Daten über Sie bei uns gespeichert sind. Bei veralteten  oder unrichtigen Informationen  werden wir diese auf Ihre Aufforderung hin berichtigen.
                    Wenn es nicht mit einer gesetzlichen Aufbewahrungspflicht von Daten kollidiert, haben Sie ein Recht auf die Sperrung oder Löschung ihrer personenbezogenen Daten.

                    <br/><br/>
                    <b>Recht auf Widerruf</b><br/>
                    Wenn es nicht mit einer gesetzlichen Aufbewahrungspflicht von Daten  kollidiert, haben Sie ein Recht auf die Sperrung oder Löschung ihrer personenbezogenen Daten. Sie sind berechtigt die Einwilligung in die Verwendung personenbezogener Daten jederzeit mit Wirkung für die Zukunft ganz oder teilweise zu Widerrufen.

                    <br/><br/>
                    <b>Beschwerderecht</b><br/>
                    bei der zuständigen Aufsichtsbehörde, der Landesdatenschutzbeauftragte des Bundeslandes in dem das Unternehmen seinen Sitz hat, steht Ihnen bei datenschutzrechtlichen Verstößen zu.

                    <br/><br/>
                    <b>Recht auf Datenübertragbarkeit</b><br/>
                    Daten die wir auf Grund  Ihrer Zustimmung und in zur Erfüllung des Auftrags automatisiert verarbeiten, können Sie an sich oder an einen Dritten in einem gängigen maschinenlesbaren Format aushändigen lassen.  Beim Verlangen einer direkten Übertragung der Daten an einen anderen Verantwortlichen erfolgt dies nur soweit es technisch möglich ist.
                    Wir behalten uns vor die Datenschutzerklärung  auf Grund von gesetzlichen Änderungen oder der Weiterentwicklung unserer Internetseite zu verändern. Daher raten wir zum erneuten Lesen der Datenschutzerklärung.
                    Bei Fragen zum Datenschutz wenden Sie sich bitte an Frau W. Belzer, Tel : 07045/200277
                    <br/><br/>

                    Stand 09.06.2018

                    <br><br>


                </p>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
