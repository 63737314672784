<template>

    <section class="section">
        <div class="container has-text-left">

            <h1 class="title is-4">Zuzahlungen</h1>

            <p>
                Bei Haushaltshilfe während der Schwangerschaft und während der Entbindung <b>entfällt</b> die Kostenbeteiligung.
                In Krankheitsfällen müssen Sie sich je nach Situation geringfügig an den Kosten einer Haushaltshilfe beteiligen.
                Sie beträgt je nach Familiensituation meist zwischen 0 &euro; und  10 &euro;. Die Zuzahlungshöhe muß in jedem Einzelfall geprüft werden, da sie von der Familiengröße, dem Einkommen und den bereits geleisteten Zuzahlungen abhängt.
                Sie können sich von Ihrer Krankenkasse oder von mir über Ihre Situation beraten lassen.
                Im folgenden finden Sie einige Informationen mit deren Hilfe sie sich auch selbst vorab ein Bild machen können.
                <br><br>
                10 Prozent der täglichen Kosten, mindestens 5 &euro; und höchstens 10 &euro; für jeden Tag, an dem Haushaltshilfe in Anspruch genommen wurde. Allerdings nicht mehr als der tägliche Gesamtaufwand.
            </p>

            <h1 class="title is-5">Befreiung von der Zuzahlung</h1>

            <p>
                Bei bestimmten Leistungen der gesetzlichen Krankenversicherung tragen Sie einen Teil der Kosten in Form der gesetzlichen Zuzahlungen selbst. Damit Sie durch diese nicht unzumutbar belastet werden, ist eine Befreiung von der Zuzahlung möglich. Ob dies für Sie zutrifft, hängt von Ihren monatlichen Bruttoeinnahmen zum Lebensunterhalt ab. Leben Angehörige mit Ihnen in einem gemeinsamen Haushalt, sind sowohl Ihre als auch deren Einnahmen zum Lebensunterhalt zu berücksichtigen. Als Angehörige gelten Ehepartner, Lebenspartner nach dem Lebenspartnerschaftsgesetz und familienversicherte Kinder.
            </p>

            <h1 class="title is-5">
                Belastungsgrenze
            </h1>

            <p>
                Die Belastungsgrenze für Zuzahlungen beträgt zwei, bei schwerwiegend chronisch Kranken ein Prozent ihrer jährlichen (Familien-Bruttoeinnahmen zum Lebensunterhalt im Kalenderjahr). Für Ehegatten/Lebenspartner und Kinder wird jeweils ein Freibetrag berücksichtigt.
            </p>


            <h1 class="title is-5">Beispiel 2021</h1>

            <table border="0" style="width:100%">
                <tr>
                    <td width="60%">
                        jährliche Bruttoeinnahmen (30.000 &euro; Ehemann, 10.000 &euro; Ehefrau,  2 Kinder ohne Einnahmen)
                    </td>
                    <td>
                        40.000 &euro;
                    </td>
                </tr>
                <tr>
                    <td>
                        abzüglich Freibetrag Ehepartner
                    </td>
                    <td>
                        &nbsp;&nbsp;5.922 &euro;
                    </td>
                </tr>
                <tr>
                    <td>
                        abzüglich Freibetrag je Kind (8.388 &euro;)
                    </td>
                    <td>
                      16.776 &euro;
                    </td>
                </tr>
                <tr>
                    <td>
                        anrechenbare Familieneinnahmen
                    </td>
                    <td>
                      17.302 &euro;
                    </td>
                </tr>

                <tr>
                    <td>
                        <b>2 Prozent davon</b>
                    </td>
                    <td>
                        <b>346,04 &euro;</b>
                    </td>
                </tr>
            </table>

            <p>
                Die Belastungsgrenze der Familie beträgt 372,86 &euro;. Zuzahlungen, die diesen Betrag übersteigen,
                erstattet die Krankenkasse auf Antrag. Überschreiten die Zuzahlungen die Belastungsgrenze im Laufe eines Kalenderjahres,
                stellt die Krankenkasse für den Rest des Jahres eine Bescheinigung zur Befreiung von der weiteren Zuzahlung aus.
            </p>

            <h1 class="title is-5">
                Schwerwiegend chronisch Kranke
            </h1>

            <p>
                Als schwerwiegend chronisch krank gilt, wer ein Jahr lang wegen derselben Erkrankung in Dauerbehandlung war, sich also mindestens einmal pro Quartal in ärztlicher Behandlung befand. Zusätzlich muss eines der folgenden Kriterien erfüllt sein:
                <br><br>
                Es liegt eine Pflegebedürftigkeit der Pflegestufe II oder III vor.
                Es liegt eine Minderung der Erwerbsfähigkeit oder ein Grad der Behinderung von mindestens 60 Prozent vor, die zumindest auch durch diese Erkrankung begründet sein muss.
                Es ist eine kontinuierliche medizinische Versorgung (ärztliche oder psychotherapeutische Behandlung, Arzneimitteltherapie, Behandlungspflege, Versorgung mit Heil- und Hilfsmitteln) erforderlich, ohne die nach ärztlicher Einschätzung eine lebensbedrohliche Verschlimmerung der Erkrankung oder eine Verminderung der Lebenserwartung oder eine dauerhafte Beeinträchtigung der Lebensqualität auf Grund dieser Erkrankung zu erwarten ist.
                <br><br>
                Wenn mindestens ein Angehöriger des Familienhaushalts schwerwiegend chronisch krank ist, reduziert sich die Belastungsgrenze für alle Angehörigen des Familienhaushalts auf ein Prozent der jährlichen (Familien-) Bruttoeinnahmen im Kalenderjahr.
            </p>
            <h1 class="title is-5">
                Wie beantragen Sie eine Befreiung?
            </h1>
            <p>
                Zuzahlungen sind während jedes Kalenderjahres nur bis zu einer individuellen Belastungsgrenze zu leisten. Um die geleisteten Zuzahlungen belegen zu können, sammeln Sie bitte sämtliche Quittungen. Diese müssen auf Ihren oder den Namen Ihres Angehörigen ausgestellt sein. Um Ihre Dokumentation zu vereinfachen, können Sie bei Ihrer Krankenkasse Nachweishefte für Ihre Zuzahlungen anfordern.
            </p>
            <br><br><br>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
    padding-bottom: 20px;
}
    table {
        margin-bottom: 20px;
    }
</style>
